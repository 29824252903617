var GlobalCampaign = {
    openModal: function () {
        $('.GlobalCampaign .modal').each(function () {
            var $modal = $(this);           
            $modal.modal();
        });
    },
    hideModal: function () {
        $('.GlobalCampaign .modal').each(function () {
            var $modal = $(this);
            $modal.on('hide.bs.modal', function (e) {

                var $globalCampaignContainer = $modal.closest(".GlobalCampaign");
                if ($globalCampaignContainer) {

                    const url = '/api/editorialcontent/globalcampaign/set';
                 
                    var formData = new FormData();
                    formData.append("key", $globalCampaignContainer.data("campaignkey"));

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    };
                    return $.ajax({
                        url: url,
                        method: "POST",
                        data: formData,
                        settings: config,
                        processData: false,
                        contentType: false,
                        cache: false

                    });
                }
            })
            
        });
    },

    init: function () {
        GlobalCampaign.openModal();
        GlobalCampaign.hideModal();
    }
}
$(function () {
    GlobalCampaign.init();
});